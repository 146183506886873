exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-csr-tsx": () => import("./../../../src/pages/csr.tsx" /* webpackChunkName: "component---src-pages-csr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-subscribed-tsx": () => import("./../../../src/pages/subscribed.tsx" /* webpackChunkName: "component---src-pages-subscribed-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

