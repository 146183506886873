import React from 'react'
import { Provider } from 'react-redux'
import store from './src/state/store'

// Carousels
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-multi-carousel/lib/styles.css'
import "react-medium-image-zoom/dist/styles.css"

import './src/styles/global.scss'

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>
}
